import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import isNil from 'utils/isNil';
import ComparisonTable from 'components/Page/ComparisonTemplate/ComparisonTable';
import { colorTypeHelper } from 'utils/colorUtility';

const ComparisonTableSection = ({
  background,
  title,
  description,
  tableData,
  otherLogo,
  ...otherProps
}) => {
  const backgroundColor = colorTypeHelper[background];

  return (
    <SectionComparisonTable backgroundColor={backgroundColor} {...otherProps}>
      <div className="section-comparison-table--container">
        <h2 className="section-comparison-table--title">{title}</h2>
        <p className="section-comparison-table--description">{description}</p>
        <div className="section-comparison-table--content">
          <ComparisonTable
            tableData={tableData}
            otherLogo={!isNil(otherLogo) ? otherLogo.file.url : ''}
          />
        </div>
      </div>
    </SectionComparisonTable>
  );
};

ComparisonTableSection.propTypes = {
  title: PropTypes.string,
  awards: PropTypes.array,
};

const SectionComparisonTable = styled.div`
  background: var(${(props) => props.backgroundColor});

  .section-comparison-table--container {
    padding: 80px 28px;
    margin: 0 auto;
    text-align: center;

    .section-comparison-table--title {
      font-weight: var(--font-weight-900);
      font-size: var(--font-size-44);
      line-height: var(--line-height-110);
      letter-spacing: var(--letter-spacing--0-04);
      color: var(--darkest);
    }

    .section-comparison-table--description {
      font-weight: var(--font-weight-500);
      font-size: var(--font-size-20);
      line-height: var(--line-height-150);
      color: var(--dark);
      padding: 0;
      margin: 24px 0 0;
    }

    .section-comparison-table--content {
      margin-top: 100px;
    }
  }

  @media (max-width: 767px) {
    .section-comparison-table--container {
      padding: 48px 28px;

      .section-comparison-table--title {
        font-size: var(--font-size-28);
        letter-spacing: var(--letter-spacing--0-03);
      }

      .section-comparison-table--content {
        margin-top: 17px;
      }
    }
  }

  @media (max-width: 575px) {
    .section-comparison-table--container {
      padding: 48px 20px;

      .section-comparison-table--content {
        margin-top: 17px;
      }
    }
  }
`;

export default ComparisonTableSection;

import React from 'react';
import styled from 'styled-components';
import Table from 'components/Core/Table';
import { STLogo } from 'components/Brand/Logos/STLogo';
import { STIcon } from 'components/Brand/Icons/STIcon';
import WebsiteImage from 'components/WebsiteImage';

const ComparisonTable = ({ tableData, otherLogo, ...otherProps }) => {
  const columns = [
    {
      title: 'FEATURES',
      dataIndex: 'features',
      key: 'features',
      render: (features, record) => {
        let column_content = null;

        if (features.type == 'text') {
          column_content = (
            <p className="comparison-table--column-features-text">{features.content.title}</p>
          );
        } else if (features.type == 'title-option') {
          column_content = (
            <div>
              <p className="comparison-table--column-features-title">{features.content.title}</p>
              <ul className="comparison-table--column-features-options">
                {features.content.option.map((optionItem, index) => {
                  return <li key={index}>{optionItem}</li>;
                })}
              </ul>
            </div>
          );
        }

        return (
          <div className={'comparison-table--column-content column-features type-' + record.type}>
            {column_content}
          </div>
        );
      },
    },
    {
      title: (
        <div>
          <div className="comparison-table--servicetitan-top-outer" />
          <STLogo />
        </div>
      ),
      dataIndex: 'servicetitan',
      key: 'servicetitan',
      render: (servicetitan, record) => {
        let column_content = null;

        if (servicetitan.type == 'icon') {
          if (servicetitan.content == 'tick') {
            column_content = <STIcon type="icon-table-tick" />;
          } else if (servicetitan.content == 'untick') {
            column_content = <STIcon type="icon-table-untick" />;
          }
        } else if (servicetitan.type == 'text') {
          column_content = <p>{servicetitan.content}</p>;
        }

        return (
          <div
            className={'comparison-table--column-content column-servicetitan type-' + record.type}
          >
            {column_content}
          </div>
        );
      },
    },
    {
      title: <WebsiteImage image={otherLogo} />,
      dataIndex: 'other',
      key: 'other',
      render: (other, record) => {
        let column_content = null;

        if (other.type == 'icon') {
          if (other.content == 'tick') {
            column_content = <STIcon type="icon-table-tick" />;
          } else if (other.content == 'untick') {
            column_content = <STIcon type="icon-table-untick" />;
          }
        } else if (other.type == 'text') {
          column_content = <p>{other.content}</p>;
        }

        return (
          <div className={'comparison-table--column-content column-other type-' + record.type}>
            {column_content}
          </div>
        );
      },
    },
  ];

  return (
    <ComparisonTableContainer>
      <div className="comparison-table--container">
        <Table columns={columns} dataSource={tableData} pagination={false} />
      </div>
    </ComparisonTableContainer>
  );
};

ComparisonTable.propTypes = {};

ComparisonTable.defaultProps = {};

const ComparisonTableContainer = styled.div`
  .comparison-table--container {
    max-width: 1220px;
    margin: 0 auto;

    .comparison-table--column-content {
      padding: 20px 31px 16px;
      min-height: 52px;
      @media (max-width: 767px) {
        padding: 15px;
      }
      p {
        font-weight: var(--font-weight-500);
        font-size: var(--font-size-16);
        line-height: var(--line-height-150);
        display: flex;
        align-items: center;
        color: var(--grey-8);
        margin: 0;
        @media (max-width: 767px) {
          font-size: var(--font-size-13);
        }
      }

      &.type-subhead {
        padding: 15px 31px 13px;
        background: var(--skyline-1);
      }

      &.column-features {
        .comparison-table--column-features-title {
          font-weight: bold;
        }

        .comparison-table--column-features-options {
          margin-top: 20px;
          padding-inline-start: 30px;

          li {
            font-weight: var(--font-weight-500);
            font-size: var(--font-size-16);
            line-height: var(--line-height-150);
            color: var(--dark);
            @media (max-width: 767px) {
              font-size: var(--font-size-13);
            }
          }
        }
      }

      &.column-servicetitan,
      &.column-other {
        p {
          justify-content: center;
        }
      }
    }

    .comparison-table--servicetitan-top-outer {
      position: absolute;
      top: -13px;
      left: -2px;
      width: calc(100% + 4px);
      height: 15px;
      background: var(--titan-blue-3);
      border-radius: 0;
      border: 2px solid var(--titan-blue-3);
    }
  }

  .MuiTableContainer-root {
    box-shadow: 0px 15px 40px rgba(11, 26, 33, 0.1);
    overflow: visible;
  }

  .MuiTable-root {
    thead {
      tr {
        th {
          background: var(--grey-8);
          height: 67px;
          border-radius: 0;
          font-family: var(--font-family-body);

          &:nth-child(1) {
            padding: 20px 31px;
            font-weight: bold;
            font-size: var(--font-size-20);
            line-height: var(--line-height-120);
            letter-spacing: var(--letter-spacing--0-01);
            color: var(--grey-1);
            width: 30%;
            @media (max-width: 767px) {
              padding: 15px;
            }
          }

          &:nth-child(2) {
            width: 310px;
            padding: 0;
            text-align: center;
            border-left: 2px solid var(--titan-blue-3);
            border-right: 2px solid var(--titan-blue-3);
            position: relative;
            background: var(--titan-blue-3);
            vertical-align: top;
            padding-top: 5px;
            @media (max-width: 767px) {
              vertical-align: middle;
              padding-bottom: 15px;
              width: 40%;
            }
            &:before {
              position: absolute;
              content: '';
              top: 0;
              width: 5px;
              height: calc(100% + 1px);
              left: 0;
              box-shadow: -8px 0px 8px rgba(11, 26, 33, 0.15);
            }

            &:after {
              position: absolute;
              content: '';
              top: 0;
              width: 5px;
              height: calc(100% + 1px);
              right: 0;
              box-shadow: 8px 0px 8px rgba(11, 26, 33, 0.15);
            }

            div {
              svg {
                height: 40px;
                padding: 0 100px;
                @media (max-width: 767px) {
                  max-width: 75%;
                  padding: 0;
                }
              }
            }
          }

          &:nth-child(3) {
            padding: 0 10px;
            text-align: center;

            img {
              width: 118px;
              max-width: 100%;
              margin: 0 auto;
            }
          }

          &:last-child {
            border-radius: 0;
          }
        }
      }
    }

    .MuiTableBody-root {
      .MuiTableRow-root {
        td {
          padding: 0;

          &:nth-child(1) {
          }

          &:nth-child(2) {
            text-align: center;
            border-left: 2px solid var(--titan-blue-3);
            border-right: 2px solid var(--titan-blue-3);
            position: relative;

            &:before {
              position: absolute;
              content: '';
              top: 0;
              width: 5px;
              height: calc(100% + 1px);
              left: 0;
              box-shadow: -8px 0px 8px rgba(11, 26, 33, 0.15);
            }

            &:after {
              position: absolute;
              content: '';
              top: 0;
              width: 5px;
              height: calc(100% + 1px);
              right: 0;
              box-shadow: 8px 0px 8px rgba(11, 26, 33, 0.15);
            }
          }

          &:nth-child(3) {
            text-align: center;
            width: 250px;
          }
        }

        &:last-child {
          td {
            border-bottom: none;

            &:nth-child(2) {
              border-bottom: 2px solid var(--titan-blue-3);
              box-shadow: 0px 8px 8px rgba(11, 26, 33, 0.15);
            }
          }
        }
      }
    }
  }
`;

export default ComparisonTable;

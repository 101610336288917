import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DescriptionWithIcon from './DescriptionWithIcon';
import { CircleCheckboxIcon } from 'components/Brand/Icons/CircleCheckboxIcon';
import { colorTypeHelper } from 'utils/colorUtility';

const ComparisonBulletSection = ({ background, title, description, bullets, ...otherProps }) => {
  const backgroundColor = colorTypeHelper[background];

  return (
    <SectionComparisonBullet backgroundColor={backgroundColor} {...otherProps}>
      <div className="section-comparison-bullet--container">
        <h2 className="section-comparison-bullet--title">{title}</h2>
        <p className="section-comparison-bullet--description">{description}</p>
        <div className="section-comparison-bullet--content">
          {bullets.map((bulletItem, index) => {
            return (
              <div className="section-comparison-bullet--item" key={index}>
                <DescriptionWithIcon
                  className="section-comparison-bullet--item-content"
                  iconImage={<CircleCheckboxIcon color={'var(--titan-blue-3)'} />}
                  marginMobile="10px 0 0 0"
                >
                  <h2 className="section-comparison-bullet--title">{bulletItem.title}</h2>
                  <p className="section-comparison-bullet--description">{bulletItem.description}</p>
                </DescriptionWithIcon>
              </div>
            );
          })}
        </div>
      </div>
    </SectionComparisonBullet>
  );
};

ComparisonBulletSection.propTypes = {
  title: PropTypes.string,
  awards: PropTypes.array,
};

const SectionComparisonBullet = styled.div`
  background: var(${(props) => props.backgroundColor});

  .section-comparison-bullet--container {
    padding: 80px 28px;
    max-width: 990px;
    margin: 0 auto;
    text-align: center;

    .section-comparison-bullet--title {
      font-weight: var(--font-weight-900);
      font-size: var(--font-size-44);
      line-height: var(--line-height-110);
      letter-spacing: var(--letter-spacing--0-04);
      color: var(--darkest);
    }

    .section-comparison-bullet--description {
      font-weight: var(--font-weight-500);
      font-size: var(--font-size-20);
      line-height: var(--line-height-150);
      color: var(--dark);
      padding: 0;
      margin: 24px 0 0;
    }

    .section-comparison-bullet--content {
      margin-top: 60px;

      .section-comparison-bullet--item {
        width: 50%;
        display: inline-flex;
        padding: 18px 20px;

        .section-comparison-bullet--item-content {
          text-align: left;

          .section-comparison-bullet--title {
            font-weight: bold;
            font-size: var(--font-size-20);
            line-height: var(--line-height-120);
            letter-spacing: var(--letter-spacing--0-01);
          }

          .section-comparison-bullet--description {
            font-weight: var(--font-weight-500);
            font-size: var(--font-size-20);
            line-height: var(--line-height-150);
            margin: 12px 0 0;
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .section-comparison-bullet--container {
      padding: 48px 28px;

      .section-comparison-bullet--title {
        font-size: var(--font-size-28);
        letter-spacing: var(--letter-spacing--0-03);
      }

      .section-comparison-bullet--content {
        margin-top: 17px;
      }
    }
  }

  @media (max-width: 575px) {
    .section-comparison-bullet--container {
      padding: 48px 20px;

      .section-comparison-bullet--content {
        margin-top: 17px;

        .section-comparison-bullet--item {
          width: 100%;
          padding: 10px;
        }
      }
    }
  }
`;

export default ComparisonBulletSection;

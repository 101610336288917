import React from 'react';
import styled from 'styled-components';
import Text from 'components/Core/Text';

const DescriptionWithIcon = ({
  children,
  iconImage,
  size,
  color,
  marginMobile,
  marginDesktop,
  ...otherProps
}) => {
  return (
    <FlexDiv>
      <IconImageDiv>{iconImage}</IconImageDiv>

      <DescriptionDiv>
        <Text
          type="p"
          size={size}
          color={color}
          marginMobile={marginMobile}
          marginDesktop={marginDesktop}
          {...otherProps}
        >
          {children}
        </Text>
      </DescriptionDiv>
    </FlexDiv>
  );
};

const FlexDiv = styled.div`
  width: 100%;
  display: flex;
`;

const IconImageDiv = styled.div`
  flex: 1;
  padding: ${(props) =>
      props.paddingTop === 'big'
        ? '15px'
        : props.paddingTop === 'normal'
        ? '13px'
        : props.paddingTop === 'small'
        ? '11px'
        : props.paddingTop}
    0 0;
`;

const DescriptionDiv = styled.div`
  width: 100%;
  padding-left: 1em;
`;

DescriptionWithIcon.defaultProps = {
  fontSize: 'big',
  color: 'var(--dark)',
  marginTop: '1em',
  marginBottom: '1em',
};

export default DescriptionWithIcon;

import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import ContentfulDynamicHero from 'components/Contentful/ContentfulDynamicHero';
import Button from 'components/Core/Button';
import SEO, { createSEO } from 'components/Core/SEO';
import ContentfulRowContent from 'components/Contentful/ContentfulRowContent';
import ContentfulContentBlock from 'components/Contentful/ContentfulContentBlock';
import ComparisonBulletSection from 'components/Page/ComparisonTemplate/ComparisonBulletSection';
import ComparisonTableSection from 'components/Page/ComparisonTemplate/ComparisonTableSection';
import WebsiteLayout from 'layouts/websiteLayout';

const bulletData = [
  {
    title: 'Step-by-Step Implementation',
    description:
      'Your personal implementation manager will ensure a seamless switch to ServiceTitan.',
  },
  {
    title: 'Secure Data Import',
    description: 'Our full-time data engineers will import key data into your new platform.',
  },
  {
    title: 'Ongoing Guidance and Tips',
    description:
      'Your Success Manager will help you adopt best practices for efficiency and growth.',
  },
  {
    title: 'Fast and Knowledgeable Support',
    description: 'Access our local support team through live chat, phone, or email.',
  },
  {
    title: 'Accessible Training Content',
    description: 'A library of materials helps your employees get up and running quickly.',
  },
  {
    title: 'Reliable Data Security',
    description:
      "ServiceTitan is built in the cloud, it's secure, and your data is always backed up.",
  },
];

const ComparisonsTemplate = (props) => {
  const {
    seo: seoContent,
    hero,
    tableData,
    tableTitle,
    competitorsLogo,
  } = props.data.contentfulComparisonPage;
  const rowContentEdges = props.data.allContentfulRowContent.edges;
  const testimonialCarousel = props.data.contentfulContentBlock;

  const seo = createSEO(seoContent, props);

  return (
    <WebsiteLayout hero={hero}>
      <SEO {...seo} />
      {hero && <ContentfulDynamicHero {...hero} />}
      <ComparisonTableSection
        title={tableTitle}
        description="Our software was built from the ground up for growth and efficiency"
        tableData={tableData}
        otherLogo={competitorsLogo}
      />
      <ComparisonHowGrowSection backgroundColor="--white">
        <div className="section-comparison-how-grow--container">
          <h2 className="section-comparison-how-grow--title">
            How Can ServiceTitan Grow Your Business?
          </h2>
          <ContentfulRowContent {...rowContentEdges[3].node} />
          <ContentfulRowContent {...rowContentEdges[2].node} />
          <ContentfulRowContent {...rowContentEdges[1].node} />
          <Button type="primary" to="/demo">
            See A Personalized Demo
          </Button>
        </div>
      </ComparisonHowGrowSection>
      <ContentfulContentBlock {...testimonialCarousel} />
      <ComparisonBulletSection
        background="Alice Blue"
        title="Onboarding with ServiceTitan"
        description="Dedicated Support to Get You Up and Running"
        bullets={bulletData}
      />
      <STComparisonLastSection>
        <div className="section-comparison-last--container">
          <ContentfulRowContent {...rowContentEdges[0].node} />
          <Button type="primary" to="/demo">
            See A Personalized Demo
          </Button>
        </div>
      </STComparisonLastSection>
    </WebsiteLayout>
  );
};

export default ComparisonsTemplate;

export const ComparisonsTemplatePageQuery = graphql`
  query ComparisonsTemplatePageQuery($slug: String!) {
    contentfulComparisonPage(seo: { pageUrl: { eq: $slug } }) {
      id
      updatedAt
      seo {
        ...Seo
      }
      headline
      tableTitle
      competitorsLogo {
        title
        description
        file {
          url
        }
      }
      tableData {
        key
        type
        other {
          type
          content
        }
        features {
          type
          content {
            title
            option
          }
        }
        servicetitan {
          type
          content
        }
      }
      hero {
        ...DynamicHero
      }
    }
    allContentfulRowContent(
      filter: {
        internalName: {
          in: [
            "Comparison Template - Section 1"
            "Comparison Template - Section 2"
            "Comparison Template - Section 3"
            "Comparison Template - Section 4"
          ]
        }
      }
    ) {
      edges {
        node {
          ...RowContent
        }
      }
    }
    contentfulContentBlock(internalName: { eq: "Comparison Template - Testimonial Carousel" }) {
      ...ContentBlock
    }
  }
`;

const ComparisonHowGrowSection = styled.div`
  background: ${(props) => `var(${props.backgroundColor})`};

  .section-comparison-how-grow--container {
    padding: 80px 28px;
    margin: 0 auto;
    text-align: center;
    @media (max-width: 767px) {
      padding-left: 15px;
      padding-right: 15px;
    }
    .section-comparison-how-grow--title {
      font-size: var(--font-size-44);
      font-weight: var(--font-weight-900);
      letter-spacing: var(--letter-spacing--0-04);
      line-height: var(--line-height-110);
      color: var(--darkest);
      @media (max-width: 767px) {
        font-size: var(--font-size-35);
      }
    }
  }
`;

const STComparisonLastSection = styled.div`
  background: ${(props) => `var(${props.backgroundColor})`};

  .section-comparison-last--container {
    padding: 0 28px 80px;
    margin: 0 auto;
    text-align: center;
    @media (max-width: 767px) {
      padding: 0 15px 60px;
    }
    .section-comparison-last--title {
      font-size: var(--font-size-44);
      font-weight: var(--font-weight-900);
      letter-spacing: var(--letter-spacing--0-04);
      line-height: var(--line-height-110);
      color: var(--darkest);
    }
  }
`;
